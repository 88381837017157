import {Injectable} from '@angular/core';

declare const fbq: (event: string, value: string, payload?: any) => void;

@Injectable({
    providedIn: 'root'
})
export class FbPixelService {

    constructor() {
    }

    track(value: string, payload: any = null) {
        fbq('track', value, payload);
    }
}
