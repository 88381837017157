import {Injectable} from '@angular/core';
import {Customer} from '../../models/Customer';
import {environment} from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import {AngularFireAnalytics} from "@angular/fire/analytics";

@Injectable({
    providedIn: 'root'
})
export class CustomerService {
    private readonly customer: Customer;

    constructor(private analytics: AngularFireAnalytics) {
        this.customer = new Customer();
    }

    async setParams(queryParamMap: URLSearchParams) {
        let token: string;
        if (!queryParamMap.has('token') && !environment.production) {
            token = window.sessionStorage.getItem('token');
        } else {
            token = queryParamMap.get('token');
            if (!environment.production) {
                window.sessionStorage.setItem('token', token);
            }
        }
        this.customer.initFromToken(token);
        Sentry.configureScope(scope => {
            scope.setUser({
                id: this.customer.getCustomerId() + '',
            });
        });
        await this.analytics.setUserId(this.customer.getCustomerId() + '');
        const firebaseUser: { [key: string]: string } = {};
        this.customer.getCities().forEach((city, index) => {
            firebaseUser['city_id_' + index + 1] = city.title;
        });
        await this.analytics.setUserProperties(firebaseUser);
        queryParamMap.delete('token');
        queryParamMap.delete('t');
        // magic trick to hide token and other query string params
        history.pushState(null, '', location.href.split('?')[0] + '?' + queryParamMap.toString());
    }

    public getCustomer(): Customer {
        return this.customer;
    }
}
