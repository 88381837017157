import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'cities',
        pathMatch: 'full'
    },
    {
        path: 'region/:region_id/cities',
        loadChildren: () => import('./pages/cities/cities.module').then(m => m.CitiesPageModule)
    },
    {
        path: 'partners',
        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersPageModule)
    },
    {
        path: 'categories/:category_tag/partners',
        loadChildren: () => import('./pages/partners/partners.module').then(m => m.PartnersPageModule)
    },
    {
        path: 'partners/:partner_id/products',
        loadChildren: () => import('./pages/products/products.module').then(m => m.ProductsPageModule)
    },
    {
        path: 'cart',
        loadChildren: () => import('./pages/cart/cart.module').then(m => m.CartPageModule)
    },
    {
        path: 'cart/:order_id/payment',
        loadChildren: () => import('./pages/payment/payment.module').then(m => m.PaymentPageModule)
    },
    {
        path: 'timedeliveries',
        loadChildren: () => import('./pages/timetables/timetables.module').then(m => m.TimetablesPageModule)
    },
    {
        path: 'dismiss',
        loadChildren: () => import('./pages/dismiss/dismiss.module').then(m => m.DismissPageModule)
    },
    {
        path: 'cart/:order_id/sca',
        loadChildren: () => import('./pages/stripe-sca/stripe-sca.module').then(m => m.StripeScaPageModule)
    },
    {
        path: 'order/:order_id/rate',
        loadChildren: () => import('./pages/rating/rating.module').then(m => m.RatingPageModule)
    },
    {
        path: 'regions',
        loadChildren: () => import('./pages/regions/regions.module').then(m => m.RegionsPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
