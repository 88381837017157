// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    fb_app_id: '367021784047153',
    base_url: 'https://stage.alfonsino.delivery/chatbot/v2/',
    stripe_pk: 'pk_test_INfQ9d8EAo1Ed3fqaVVA0KEF',
    firebase: {
        apiKey: "AIzaSyAGxJDv_8Fv4lei-lqhHOnQQc-JC1YIrQ0",
        authDomain: "alfonsino-delivery.firebaseapp.com",
        databaseURL: "https://alfonsino-delivery.firebaseio.com",
        projectId: "alfonsino-delivery",
        storageBucket: "alfonsino-delivery.appspot.com",
        messagingSenderId: "165389376776",
        appId: "1:165389376776:web:a224ae7db26b9cb0c720bd",
        measurementId: "G-DM06SDJNXB"
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */

// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
