import {Component} from '@angular/core';

import {Platform} from '@ionic/angular';
import {NavigationEnd, Router} from "@angular/router";
import {CustomerService} from "./providers/customer/customer.service";
import {FbPixelService} from "./providers/fb-pixel/fb-pixel.service";
import {AngularFireAnalytics} from "@angular/fire/analytics";

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private fbPixel: FbPixelService,
        private customerSvc: CustomerService,
        private router: Router,
        private analytics: AngularFireAnalytics
    ) {
        this.initializeApp();
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.router.events.forEach(item => {
                if (item instanceof NavigationEnd) {
                    this.fbPixel.track('PageView');
                }
            });
        });
    }
}
