import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Observable} from 'rxjs';
import {City} from '../../models/City';
import {CustomerService} from '../customer/customer.service';
import {Product} from '../../models/Product';
import {ProductCategory} from '../../models/ProductCategory';
import {ChosenProductVariation} from '../../interfaces/ChosenProductVariation';
import {DeliveryTypes} from '../../models/DeliveryTypes';
import {GetTimedeliveriesResponse} from '../../models/api/res/GetTimedeliveriesResponse';
import {GetOrderPaymentInfoResponse} from '../../models/api/res/GetOrderPaymentInfoResponse';
import {CheckoutResponse} from '../../models/api/res/CheckoutResponse';
import {SetCartProductQuantityResponse} from '../../models/api/res/SetCartProductQuantityResponse';
import {GetCitiesByRegionResponse} from '../../models/api/res/GetCitiesByRegionResponse';
import {GetCategoriesAndPartnersResponse} from '../../models/api/res/GetCategoriesAndPartnersResponse';
import {UpdateProductQuantityResponse} from '../../models/api/res/UpdateProductQuantityResponse';
import {GetCartProductsResponse} from '../../models/api/res/GetCartProductsResponse';
import {AddProductVariation} from '../../models/api/res/AddProductVariation';
import {Rating} from "../../models/Rating";
import {GetRegionsResponse} from "../../models/api/res/GetRegionsResponse";

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    private readonly endpoints = {
        get_categories_partners: 'partners',
        get_partners_tag: (category_tag: string) => `partners/tag/${category_tag}`,
        get_cities: (region_id: number) => `region/${region_id}/cities`,
        select_city: (city_id: number) => `city/${city_id}`,
        subscribe_city_lead: (city_id: number) => `city/${city_id}/subscribe`,
        get_partner_products: (partner_id: number) => `partners/${partner_id}/products`,
        cart: {
            update_cart_product: 'cart/product',
            get_cart_products: 'cart/products',
            update_cart_product_quantity: 'cart/product/quantity',
            do_checkout: 'cart/checkout',
            get_time_deliveries: 'cart/timedeliveries',
            select_timedelivery: 'cart/timedelivery',
            start_new_order: 'cart/restart',
            get_order_payment_info: (order_id: number) => `order/${order_id}`,
            check_sca_intent: (order_id: number) => `order/${order_id}/sca`,
        },
        rate_order: (order_id: number) => `order/${order_id}/rate`,
        regions: 'regions',
    };

    constructor(public httpClient: HttpClient, public customerSvc: CustomerService) {
    }

    getCitiesByRegion(region_id: number): Observable<{ cities: City[], region_title: string }> {
        return this.httpClient.get<GetCitiesByRegionResponse>(environment.base_url + this.endpoints.get_cities(region_id));
    }

    chooseCity(city_id: number) {
        return this.httpClient.post(environment.base_url + this.endpoints.select_city(city_id), {});
    }

    getCategoriesAndPartners(category_tag: string = null) {
        if (category_tag) {
            return this.httpClient.get<GetCategoriesAndPartnersResponse>(environment.base_url + this.endpoints.get_partners_tag(category_tag));
        } else {
            return this.httpClient.get<GetCategoriesAndPartnersResponse>(environment.base_url + this.endpoints.get_categories_partners);
        }

    }

    getPartnerProducts(partnerId: number) {
        return this.httpClient.get<{
            partner: { title: string },
            product_categories: ProductCategory[],
            subtotal: number,
        }>(environment.base_url + this.endpoints.get_partner_products(partnerId),);
    }

    updateProductQuantity(product: Product, quantity: number) {
        return this.httpClient.post<UpdateProductQuantityResponse>(environment.base_url + this.endpoints.cart.update_cart_product, {
            product_id: product.id,
            quantity
        });
    }

    addProductVariation(product: Product, variations: ChosenProductVariation) {
        return this.httpClient.post<AddProductVariation>(environment.base_url + this.endpoints.cart.update_cart_product, {
            product_id: product.id,
            variations,
            quantity: 1
        });
    }

    getCartProducts() {
        return this.httpClient.get<GetCartProductsResponse>(environment.base_url + this.endpoints.cart.get_cart_products);
    }

    setCartProductQuantity(cartProductIndex: number, quantity: number) {
        return this.httpClient.post<SetCartProductQuantityResponse>(environment.base_url + this.endpoints.cart.update_cart_product_quantity, {
            product_index: cartProductIndex,
            quantity,
        });
    }

    checkout() {
        return this.httpClient.post<CheckoutResponse>(environment.base_url + this.endpoints.cart.do_checkout, {});
    }

    subscribeCityLead(city: City, email: string) {
        return this.httpClient.post<any>(environment.base_url + this.endpoints.subscribe_city_lead(city.id), {
            email
        });
    }

    getTimetables() {
        return this.httpClient.get<GetTimedeliveriesResponse>(environment.base_url + this.endpoints.cart.get_time_deliveries);
    }

    setTimedelivery(time_delivery: string, delivery_type_id: DeliveryTypes) {
        return this.httpClient.post<{}>(environment.base_url + this.endpoints.cart.select_timedelivery, {
            time_delivery,
            delivery_type_id
        });
    }

    startNewOrder() {
        return this.httpClient.post<any>(environment.base_url + this.endpoints.cart.start_new_order, {});
    }

    getOrderPaymentInfo(order_id: number) {
        return this.httpClient.get<GetOrderPaymentInfoResponse>(
            environment.base_url + this.endpoints.cart.get_order_payment_info(order_id),
            {observe: 'response'});
    }

    getOrderStatus(order_id: number, payment_intent_client_secret: string) {
        return this.httpClient.post<{
            title: string;
            message: string;
        }>(environment.base_url + this.endpoints.cart.check_sca_intent(order_id),
            {payment_intent_client_secret},
            {observe: 'response'});
    }

    rateOrder(order_id: number, rating: Rating) {
        return this.httpClient.post<any>(environment.base_url + this.endpoints.rate_order(order_id), {
            'rating-driver': rating.driver.rating,
            'rating-partner': rating.partner.rating,
            'text-driver': rating.driver.text,
            'text-partner': rating.partner.text,
            'advise-value': rating.service.rating,
            'text-service': rating.service.text,
        }, {observe: 'response'});
    }


    getRegions() {
        return this.httpClient.get<GetRegionsResponse>(
            environment.base_url + this.endpoints.regions,
            {observe: 'response'});
    }
}
